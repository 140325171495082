import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { BlockRenderer, Grid, Margin, } from '@components/atoms'
import { GridItem, Hero } from '@components/molecules'
import { TransitionMask } from '@components/organisms'
import { PageProps } from '@types'
import { getCdnPath } from '@utils'
import { Helmet } from 'react-helmet'


const CareersPage = ({data: { 
  sanityPage: {
    heroType, 
    heroHeight,
    heroImageConditional, 
    heroVideoConditional,
    heroFX,
    _rawIntroduction,
    gridItems,
    gridSnap,
    inverted,
    shareImage,
    description,
    title,
  }
}}: PageProps) => {
  const parsedTitle = `TWA: ${title}`
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
  ]
  if(shareImage) {
    meta.push({ property: "og:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
    meta.push({ name: "twitter:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
  }
  return (
    <>
      <Helmet {...{meta}} title={parsedTitle} >
        <title>{parsedTitle}</title>
      </Helmet>
      <Hero {...{ heroType, heroHeight, heroImageConditional, heroVideoConditional, heroFX }} />
      <StyledMargin>
        <Grid>
          <PageIntroduction>
            <BlockRenderer>{_rawIntroduction}</BlockRenderer>
          </PageIntroduction>
        </Grid>
      </StyledMargin>
      <Margin>
        <Grid {...{ gridSnap }} >
          {gridItems.filter(p => !!p).map((item) => (
            <GridItem key={item._key} {...item} />
          ))}
        </Grid>
      </Margin>
            
      <TransitionMask {...{ inverted }} />
    </>
  )
}


const StyledMargin = styled(props => <Margin {...props} />)`
  padding: 28vw 0 10vw 0;
  @media only screen and (min-width: 744px) {
    padding: 12vw 0 5vw 0;
  }
`


const PageIntroduction = styled.header`
  grid-column: 1 / span 6;
  @media only screen and (min-width: 744px) {
    grid-column: 3 / span 9;
  }
`


export const query = graphql`
  query CareersPageQuery {
    sanityPage(slug: {current: {eq: "careers"}}) {
      ...pageFields
    }
  }
`

export default CareersPage
